<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList" :showSearch="false" :showHorizontalFilter="false"
      :propsParam="propsParam">
      <div slot="content-buttons">
        <Button _key="btnProductCreate" title="Produto" classIcon="fas fa-plus-circle" type="primary" size="small"
          :clicked="create" />
      </div>
    </ViewTemplateConfiguration>
    <Modal title="Produtos" :width="1200" :height="750" v-show="showModal('accessoryCreate')"
      :onHideModal="hideSearchProduct">
      <SearchProduct v-if="showSearchProduct" :types="'1,2,3,0,5'" :status="1" :showPrice="!recalculatePriceTable"
        :addProduct="addProduct" />
    </Modal>
    <Modal title="Produto" :width="1000" :height="750" v-show="showModal('kitRentUpdate')">
      <ItemsKitRentUpdate :kitRentId="kitRentId" :recalculatePriceTable="recalculatePriceTable" />
    </Modal>
  </div>
</template>

<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import SearchProduct from "../../stock/search-product/SearchProduct.vue";
import ItemsKitRentUpdate from "./ItemsKitRentUpdate.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "ItemsKitRentList",
  components: {
    ViewTemplateConfiguration,
    Modal,
    Button,
    SearchProduct,
    SearchProduct,
    ItemsKitRentUpdate,
  },
  props: {
    kitRentId: String,
    recalculatePriceTable: Boolean,
  },
  data() {
    return {
      templateList: {
        urlCreate: "/api/v1/rental/items-kit-rent/create",
        urlGetApi: "/api/v1/rental/items-kit-rent/get-all",
        urlOrderAllApi: "/api/v1/rental/items-kit-rent/order-all",
        urlDeleteAllApi: "/api/v1/rental/items-kit-rent/delete",
        dragAndDrop: true,
        headerTable: [],
        headerTableOptional: [
          {
            field: "productFullName",
            title: "Produto",
            type: "link",
            eventName: "kitRentUpdate",
          },
          {
            field: "description",
            title: "Descrição",
            type: "html",
          },
          {
            field: "quantity",
            title: "Quantidade",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "unitValue",
            title: "R$ Unitário",
            type: "currency",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "totalUnitValue",
            title: "R$ Total",
            type: "currency",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
        ]
      },
      propsParam: {
        any: this.kitRentId,
      },
      showSearchProduct: false,
    };
  },
  mounted() {
    this.addheaderTable();
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    ...mapActions("generic", ["postApi"]),
    addheaderTable() {
      var updatedHeaderTable = [];
      this.templateList.headerTableOptional.forEach(option => {
        if (!(option.type === "currency" && this.recalculatePriceTable)) {
          updatedHeaderTable.push(option);
        }
      });

      this.templateList.headerTable = updatedHeaderTable;
    },
    create() {
      this.openModal("accessoryCreate");
      this.addEvent({ name: "patrimonyCreate" });
      this.removeLoading(["btnProductCreate"]);
      this.showSearchProduct = true;
    },
    hideSearchProduct() {
      this.showSearchProduct = false;
    },
    addProduct(product) {
      let obj = {
        id: "",
        product: { id: product.id },
        productName: product.name,
        description: product.description,
        quantity: product.quantity,
        unitValue: product.value,
        kitRentId: this.kitRentId,
      };
      let params = { url: this.templateList.urlCreate, obj: obj };
      this.postApi(params).then((response) => {
        this.removeLoading([product.id]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "kitRentUpdate") this.openModal("kitRentUpdate");
      },
      deep: true,
    },
    recalculatePriceTable: {
      handler() {
        this.addheaderTable();
      },
      deep: true,
    },
  },
};
</script>
