<template>
  <div>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="5" xl="5">
        <Select title="Tipo" field="type" :formName="formName" :required="true"
          url="/api/v1/stock/type-product/select-all" :propsParams="{ any: 2 }" nameNewRegister="typeProductCreateUpdate"
          titleNewRegister="Tipo" :widthNewRegister="700" :heightNewRegister="250" v-model="itemsKitRent.typeProduct"
          :changed="cleanAccessory">
          <TypeProductCreateUpdate :registerInSelect="true" v-model="itemsKitRent.typeProduct" />
        </Select>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="7" xl="7">
        <Select title="Acessório" field="itemsKitRent" :formName="formName" :required="true"
          url="/api/v1/stock/product/select-all" :propsParams="{ any: itemsKitRent.typeProduct.id }"
          :disabled="!itemsKitRent.typeProduct.id" nameNewRegister="productUpdate" titleNewRegister="Acessório"
          :widthNewRegister="700" :heightNewRegister="250" v-model="itemsKitRent.product">
          <!--  <TypeProductCreateUpdate
            :registerInSelect="true"
            v-model="product.typeProduct"
          />-->
        </Select>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <EditorHtml title="Descrição" field="description" :required="false" :maxLength="500"
          v-model="itemsKitRent.description" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="2">
        <InputNumber title="Quantidade" field="quantity" :formName="formName" :required="true" :maxLength="5" type="float"
          v-model="itemsKitRent.quantity" />
      </b-col>
      <b-col sm="2">
        <InputDecimal v-if="!recalculatePriceTable" title="Valor Unitário" field="unitValue" :formName="formName"
          :required="true" :markFormDirty="false" :maxLength="5" type="float" v-model="itemsKitRent.unitValue" />
      </b-col>
      <b-col sm="8">
        <div class="div-btn text-right">
          <Button _key="btnUpdateAccessory" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="update" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import TypeProductCreateUpdate from "../../stock/type-product/TypeProductCreateUpdate.vue";
import ItemsKitRent from "./ItemsKitRent.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ItemsKitRentUpdate",
  props: {
    kitRentId: String,
    registerInSelect: Boolean,
    recalculatePriceTable: Boolean,
    value: Object,
  },
  components: {
    InputNumber,
    InputDecimal,
    Select,
    EditorHtml,
    Button,
    TypeProductCreateUpdate,
  },
  data() {
    return {
      formName: "accessoryUpdate",
      itemsKitRent: new ItemsKitRent(),
      urlUpdate: "/api/v1/rental/items-kit-rent/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", ["removeLoading", "removeEvent", "hideModal"]),
    ...mapMutations("validation", ["resetValidation"]),
    update() {
      this.itemsKitRent.kitRentId = this.kitRentId;

      let params = { url: this.urlUpdate, obj: this.itemsKitRent };
      this.putApi(params).then((response) => {
        if (response.success && !this.registerInSelect) this.hideModal();
        this.removeLoading(["btnUpdateAccessory"]);
      });
    },
    cleanAccessory() {
      this.itemsKitRent.product = { id: "", content: "" };
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "kitRentUpdate") this.itemsKitRent.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-btn {
  margin-top: 35px;
}
</style>
