export default class KitRent {
    constructor() {
        this.id = "";
        this.name = "";
        this.description = "";
        this.recalculatePriceTable = false;
        this.status = 1;
    }
    update(data) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.recalculatePriceTable = data.recalculatePriceTable;
        this.status = data.status;
    }
}