export default class TypeProduct {
    constructor() {
        this.id = "";
        this.name = "";
        this.type = 1;
    }
    update(data) {
        this.id = data.id;
        this.name = data.name;
        this.type = data.type;
    }
}