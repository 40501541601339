<template>
  <div>
    <ViewTemplateWithSalveCancel :panel="panel">
      <div slot="content-buttons">
        <Button v-if="id" _key="btnProductCreate" title="Kit" classIcon="fas fa-plus-circle" type="primary" size="small"
          eventName="productCreate" :clicked="create" />
      </div>
      <div slot="content-main">
        <KitRentCreateUpdate :formName="panel.formName" />
      </div>
    </ViewTemplateWithSalveCancel>
  </div>
</template>

<script>
import ViewTemplateWithSalveCancel from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithSalveCancel";
import KitRentCreateUpdate from "../../../components/modules/rental/kit-rent/KitRentCreateUpdate.vue";

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "KitRentCreateUpdateView",
  components: {
    Button,
    Molded,
    ViewTemplateWithSalveCancel,
    KitRentCreateUpdate,
  },
  data() {
    return {
      id: this.$route.params.id,
      panel: {
        module: "Estoque",
        title: "Kit",
        formName: "kitRentCreateUpdate",
        showFilter: false,
        showSearch: false,
        showButtons: true,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    create() {
      this.$router.push({
        name: "kitRentCreate",
      });
      this.removeLoading(["btnProductCreate"]);
    },
  },
};
</script>
