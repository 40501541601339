<template>
  <div class="margin">
    <Molded>
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="5" xl="5">
          <InputText title="Nome" field="name" :formName="formName" :required="true" :maxLength="200"
            v-model="kitRentCreateUpdate.name" />
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="7" xl="7">
          <InputText title="Descrição" field="document" :formName="formName" :maxLength="500"
            v-model="kitRentCreateUpdate.description" />
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6" v-if="id">
          <RadioGroup title="Status" field="status" :formName="formName" :options="[
            { text: 'Ativo', value: 1 },
            { text: 'Inativo', value: 2 },
          ]" v-model="kitRentCreateUpdate.status" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <RadioGroup title="Recalcular os valores ao importar na locação?" :formName="formName" :options="[
            { text: 'Sim', value: true },
            { text: 'Não', value: false },
          ]" v-model="kitRentCreateUpdate.recalculatePriceTable" />
        </b-col>
      </b-row>
    </Molded>
    <br />
    <b-tabs v-if="id">
      <b-tab title="Produto">
        <ItemsKitRentList :kitRentId="id" :recalculatePriceTable="kitRentCreateUpdate.recalculatePriceTable" />
      </b-tab>
    </b-tabs>
    <br />
  </div>
</template>

<script>

import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import KitRent from "@/components/modules/rental/kit-rent/KitRent.js";
import ItemsKitRentList from '../items-kit-rent/ItemsKitRentList.vue'

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "KitRentCreateUpdate",
  props: {
    value: Object,
  },
  components: {
    InputText,
    RadioGroup,
    CheckboxGroup,
    Molded,
    Button, ItemsKitRentList
  },
  data() {
    return {
      id: this.$route.params.id,
      formName: "bankAccountCreateUpdate",
      kitRentCreateUpdate: new KitRent(),
      urlCreate: "/api/v1/rental/kit-rent/create",
      urlUpdate: "/api/v1/rental/kit-rent/update",
      urlGetById: "/api/v1/rental/kit-rent/get-by-id",
    };
  },
  created() {
    if (this.id) this.getById();
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "isLoading"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    ...mapMutations("generic", ["removeLoading"]),
    ...mapMutations("validation", ["removeFormDirty"]),
    create() {
      let params = { url: this.urlCreate, obj: this.kitRentCreateUpdate };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.$router.replace({
            name: "kitRentUpdate",
            params: { id: response.content.id },
          });
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    update() {
      let params = { url: this.urlUpdate, obj: this.kitRentCreateUpdate };
      this.putApi(params).then((response) => {
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    getById() {
      let params = { url: this.urlGetById, obj: { id: this.id } };
      this.getApi(params).then((response) => {
        this.kitRentCreateUpdate.update(response.content);
        let self = this;
        setTimeout(function () {
          self.removeLoading(["panel"]);
          self.removeFormDirty();
        }, 100);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel") {
          if (!this.id) this.create();
          if (this.id) this.update();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.margin {
  margin-right: 10px;
}

.div-balance {
  font-size: 16px;
  margin-top: 3px;
}

.positive {
  color: darkblue;
}

.negative {
  color: red;
}
</style>
